#myDesign {
  position: relative;
  width: 500px;
  height: 500px;
  background: radial-gradient(118.82% 81.71% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
border-radius: 20px;

}

.text-container {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  text-align: center;
}

.text-stage{
  position: absolute;
  bottom: 25px;
}
