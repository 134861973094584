.dashboard-title h1 {
    font-size: 35px;
    color: #ffffff;
}

.order-details-header h2 {
    color: #ffffff;
    font-size: 25px;
}

.dashboard-design {
    margin-top: 2%;
    margin-bottom: 2%;
}

.fixed-height {
    height: 190px;
}

.sora-regular {
    font-family: 'Sora-regular' !important;
}

.sora-bold {
    font-family: 'Sora-bold' !important;
}

.btn-tranparent,
.btn-tranparent:hover {
    background: transparent;
    border: 2px solid #ffc300;
    border-radius: 5px;
    margin-top: 10px;
}

.shawdow-btn {
    border: 2px solid #ffc300;
    background: #000000;
    color: #ffc300;
    font-size: 18px;
    box-shadow: -4px 10px 6px #ffc300, -4px 3px 6px #ffc300;
}

.shawdow-btn:hover {
    border: 2px solid #ffc300;
    background: #000000;
    color: #ffc300;
}

.customize {
    color: #ffffff;
    font-size: 20px;
}

.chart-div {
    margin-top: 2%;
    margin-bottom: 3%;
}

.yellow-card h4 {
    color: #ffc300;
    font-size: 24px;
}

.yellow-card p {
    margin-top: 15px;
    color: #ffffff;
    font-size: 16px;
}

.design-content {
    padding: 5%;
}

.yellow-card {
    background: linear-gradient(to bottom right, rgb(0 0 0 / 0%), #ffc40061);
    border-radius: 20px;
    border: none;
}

.light-card {
    background: #0000007a;
    border-radius: 20px;
    border: none;
}

.dark-card {
    background: #000000;
    border-radius: 20px;
    border: none;
    z-index: 1;
}

.dark-card .card-body {
    padding: 35px;
}

.orders-icon {
    background: #292200;
    border-radius: 25px;
    padding: 5% 5% 5% 5%;
}

.order-details-cards {
    margin-top: 3%;
    margin-bottom: 3%;
}

.orders-details {
    padding-left: 15%;
}

.orders-details h3 {
    font-size: 20px !important;
    color: #ffffff;
}

.orders-details p {
    font-size: 16px !important;
    color: #ffffff7c;
}

.orders-details h3 {
    font-family: "Oswald-Bold";
}

.orders-details p {
    font-family: "Nunito-Regular";
    margin-bottom: 0;
}

.orders-content {
    display: flex;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 2%;
    padding-right: 2%;
}

.table-checkbox {
    height: 15px;
}


/* table css */

.MuiTable-root td,
.MuiTable-root th {
    border: unset;
    border-right: 1px solid #ffc3002b;
    border-bottom: 1px solid #ffc3002b;
}

.MuiTable-root td:last-child,
.MuiTable-root th:last-child {
    border-right: unset;
}

.MuiTable-root td {
    color: #fff !important;
}

.MuiPaper-root {
    /* background: linear-gradient(to bottom right, rgb(0 0 0 / 0%), #ffc40061); */
    background-color: transparent !important;
}

.MuiTypography-h6 {
    color: #ffc300;
    font-family: 'Sora-regular' !important;
}

.MuiSvgIcon-root {
    fill: #fff !important;
}

.MuiTypography-caption {
    color: #fff;
}

.MuiPaper-root-1 {
    background-color: transparent !important;
}

.MuiToolbar-root-33 {
    background-color: #000000c4 !important;
}

.MuiToolbar-root-33 div {
    color: #ffffff !important;
}

.MuiInputBase-input {
    border: none !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    color: #ffffff !important;
    padding: 0px !important;
}

.MuiTypography-root-37 {
    color: #ffffff !important;
}

.MuiTableCell-head,
.MuiTableCell-head:hover {
    color: #ffffff9e !important;
    font-size: 18px !important;
    font-family: 'Sora-regular' !important;
    background-color: unset !important;
}

.MuiTableCell-body-142 {
    color: #ffffff !important;
    font-size: 16px !important;
    font-family: 'Sora-regular' !important;
}

.MuiTablePagination-root-168 {
    border: none;
    border-radius: 15px;
}

.MuiTablePagination-toolbar-169 {
    border-radius: 15px;
    font-family: 'Sora-regular' !important;
    font-size: 16px;
}

.MuiTableRow-head-138 .MuiTableCell-root-140 {
    border: none;
    border-top: 1px solid rgb(107, 107, 107) !important;
}

.MuiTableRow-root-135 .MuiTableCell-root-140 {
    border: none;
    border-bottom: 1px solid rgb(107, 107, 107) !important;
    padding-top: 20px;
    padding-bottom: 20px;
}

.MuiTable-root-133 td {
    border: none !important;
}

.MTableToolbar-root-28 {
    padding: 25px !important;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.MuiPaper-rounded-2 {
    border-radius: 15px !important;
    border: 1px solid rgba(168, 168, 168, 0.249);
}

.MuiTableRow-footer-139 {
    background: #000000;
}

.MuiTablePagination-caption-181,
.MuiTablePagination-caption-171 {
    font-size: 16px !important;
    font-family: 'Sora-regular' !important;
}

.MTablePaginationInner-root-195 span {
    color: #ffffff;
}

.MuiTablePagination-selectIcon-184,
.MuiTablePagination-selectIcon-174 {
    top: 13px !important;
    left: 10px;
}

.MuiTablePagination-select-183,
.MuiTablePagination-select-172 {
    padding: 8px !important;
    font-size: 15px !important;
}

.MuiList-padding-206,
.MuiList-padding-217 {
    background: rgba(0, 0, 0, 0.737);
}

.MuiList-padding-217 li {
    color: #ffffff;
}

.MuiTable-root-133 {
    margin-bottom: 0px !important;
}

.MuiTableRow-footer-139 {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.MuiTablePagination-root-178,
.MuiTablePagination-toolbar-179 {
    border-bottom-right-radius: 15px !important;
}

.MuiIconButton-label-125 {
    color: #ffffff;
}

.MuiFormControl-root-73 {
    background-color: #101010 !important;
    border-radius: 15px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.MuiTypography-h6-54 {
    font-family: 'Sora-regular' !important;
}

.MuiTableHead-root-134 {
    background: #000000;
    border-top: 1px solid rgba(168, 168, 168, 0.249);
}

.table-actions {
    display: inline-flex;
}

.table-actions Button,
.table-actions Button:focus,
.table-actions Button:active,
.table-actions Button:hover {
    margin-right: 5px;
    background-color: #000000;
    border: 1px solid #ffc300;
    color: #ffc300;
}

.table-product-name {
    color: #ffc300;
}

.MuiTableSortLabel-root-152:hover {
    color: #ffffff9e !important;
}

.MuiTableCell-root-140:last-child {
    border: none;
}

.MuiInput-underline-81:after {
    border-bottom: none !important;
}

.table-product-image {
    /* background: gray;
    border-radius: 7px; */
}

.table-product-image img {
    width: 100px;
}

.MuiTableBody-root-166 .MuiTableRow-root-135 td:first-child {
    width: 30% !important;
}

.table-product-detail {
    margin-left: 10px;
}

.table-product-detail h6 span {
    margin-left: 20px;
}

.MuiInputBase-root-90 {
    height: 50px;
}

.trans-link {
    color: #ffc300;
    font-family: 'Sora-regular' !important;
}