.add-products .label {
    color: #fff;
    font-size: 20px;
}

.add-products .field {
    padding-bottom: 30px;
}

.add-products input {
    padding-left: 10px;
}

.add-products .form-outer {
    padding-bottom: 50px;
}

.product-addition-form input::placeholder,
.product-addition-form input::-webkit-input-placeholder {
    color: #969696 !important;
}

.product-addition-form .card .card-header {
    background-color: #292200;
}

.add-special-token-btn {
    background-color: #ffc300;
    color: #000;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 600;
}

.add-special-token-btn:hover {
    background-color: #ffc300;
    color: #000;
}

.modal-hidden {
    display: none !important;
}

.modal-content {
    background-color: #292200;
}

.modal-text {
    color: #fff;
}

.modal-header .btn-close {
    color: #fff;
}

.add-products-page{
    padding-left: 17%;
}

.product-help-text{
    font-size: 16px;
}