.product-card {
    background: linear-gradient(180deg, #ffc4001a, #ffc40000);
    border: none;
    border-radius: 15px;
    padding: 15px;
    margin-right: 50px;
    margin-top: 10px;
}

.product-card-heading {
    margin-top: 30px;
    margin-bottom: 50px;
}

.product-card-body h1 {
    color: #ffffffd4;
    font-family: 'Sora-bold' !important;
    font-size: 30px;
    line-height: 0;
}

.product-card-body h3 {
    color: #ffffffd4;
    font-family: 'Sora-regular' !important;
    font-size: 18px;
    margin-top: 30px;
}

.cart-details {
    padding-bottom: 40px;
    /* border-bottom: 1px solid #ffffff; */
}

.product-card-heading h1 span {
    float: right;
    font-size: 21px;
}

.product-image {
    width: 100%;
}

.breadcrumbs-list {
    padding-left: 0;
}

.product-card-body h4 {
    color: #ffffff;
}

.order-status-seleact-box option {
    background: #0c0b03;
    font-size: 20px;
}