@font-face {
    font-family: 'Sora-regular';
    src: url(../src/Fonts/TTF/Sora-Regular.ttf);
}

@font-face {
    font-family: 'Sora-bold';
    src: url(../src/Fonts/TTF/Sora-Bold.ttf);
}

:root {
    --main-font: 'Sora-bold';
    --header-color-1: #141414;
    --header-color-2: #201a07;
    --body-background: #151102;
    --body-color-1: #141414e9;
    --body-color-2: #201a07e9;
    --body-color-3: #292200;
    --body-color-4: #ffc4001a;
    --button-bg-color-1: #ffc300;
    --button-bg-color-2: #000000;
    --button-bg-color-3: #595959;
    --button-font-color-1: #fff;
    --button-font-color-2: #fff;
    --border-color-1: #3A2E21;
    --border-color-2: rgba(255, 195, 0, 1);
    --font-color-1: #ffc300;
    --font-color-2: #fff;
    --font-color-3: #d0cfcc;
    --font-color-4: #ffc300;
    --form-bg-color-1: #231F11;
    --form-font-color-1: #969696;
    --icon-color-1: #ffc300;
    --icon-color-2: #FFC300;
    --popup-bg-color-1: #483a07;
    --popup-font-color-1: #fff;
    --popup-button-bg-color-2: #ffc300;
    --popup-button-font-color-2: #000;
    --card-bg-color-1: #ffc4001a;
    --card-bg-color-2: #ffc40000;
    --loader-color: #ffc300;
}