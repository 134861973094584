.shopping-cart-main {
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.cart-heading h1 {
    color: var(--font-color-1);
    font-family: 'Sora-bold' !important;
    font-size: 35px;
}

.cart-content {
    margin-top: 4%;
}

.shadow-btn {
    margin-top: 30px;
    border: 2px solid #ffc300;
    background: var(--button-bg-color-2);
    color: var(--button-font-color-1);
    font-size: 18px;
    box-shadow: -4px 10px 6px #ffc300, -4px 3px 6px #ffc300;
}

.shadow-btn:active,
.shadow-btn:focus,
.shadow-btn:hover {
    /* border: 2px solid var(--button-bg-color-2) !important; */
    background-color: var(--button-bg-color-2) !important;
    color: var(--button-font-color-1) !important;
}

.shadow-btn-width {
    width: 100%;
}

.shadow-btn-width img {
    padding-left: 10%;
    display: inline-flex;
}

.form-input {
    background: #D9D9D9;
    margin-top: 0px;
    font-family: 'Sora-regular' !important;
}

.form-input::placeholder {
    font-family: 'Sora-regular' !important;
}

.shopping-card {
    background: linear-gradient(180deg, var(--card-bg-color-1), var(--card-bg-color-2));
    border: none;
    border-radius: 15px;
    padding: 15px;
    margin-right: 50px;
}

.card-heading {
    margin-top: 30px;
    margin-bottom: 50px;
}

.shopping-card-body h1 {
    color: #ffffffd4;
    font-family: 'Sora-bold' !important;
    font-size: 30px;
}

.shopping-card-body h3 {
    color: #ffffffd4;
    font-family: 'Sora-regular' !important;
    font-size: 18px;
    margin-top: 30px;
}

.cart-details {
    padding-bottom: 40px;
    border-bottom: 1px solid #cbd5e0;
}

.place-order {
    margin-top: 30px;
}

.cart-total {
    margin-top: 50px;
}

.shopping-card-body h3 span,
.shopping-card-body h1 span {
    float: right;
}

.cart-table-head tr th,
.cart-table-body tr td {
    border: none;
}

.cart-table-body tr td .quantity {
    display: inline-flex;
}

.cart-table-body tr td .quantity h1 {
    font-size: 26px;
    color: var(--font-color-3);
    padding-left: 6px;
    padding-right: 6px;
    /* padding-top: 5px; */
}

.cart-table-body tr td .quantity img {
    width: 40px;
    height: 40px;
}

.cart-table-head tr th {
    color: #ffffff;
    font-family: 'Sora-regular' !important;
    font-size: 18px;
}

.cart-table-body tr td h3 {
    color: var(--font-color-2);
    font-family: 'Sora-regular' !important;
    font-size: 22px;
}

.cart-table-body tr td h5 {
    color: var(--font-color-3);
    font-family: 'Sora-regular' !important;
    font-size: 19px;
}

.cart-table-body tr td h6 {
    color: #ffffffcf;
    font-family: 'Sora-regular' !important;
    font-size: 14px;
}

.cart-table-body tr td h4 {
    margin-top: 20px;
    color: #FF073A;
    font-family: 'Sora-regular' !important;
    font-size: 16px;
}

.cart-table-head tr,
.cart-table-body tr {
    border-bottom: 1px solid #ffffff3e;
}

.cart-table-body tr:last-child {
    border-bottom: none !important;
}

.cart-table-body tr td {
    padding-top: 35px;
    padding-bottom: 45px;
}

.table-row-total {
    color: var(--font-color-2);
    font-family: 'Sora-bold' !important;
    font-size: 23px;
}

.table-productSelectedSize {
    color: #ffffff;
    font-family: 'Sora-regular' !important;
    font-size: 23px;
}

.product-remove {
    cursor: pointer;
    margin-top: 20px;
    color: #FF073A;
    font-family: 'Sora-regular' !important;
    font-size: 16px;
}

.mobile-place-order,
.mobile-cart-table {
    display: none;
}

.desktop-place-order,
.desktop-cart-table {
    display: block;
}

.mobile-place-order button {
    width: 100% !important;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    border: none;
    /* border-top: 2px solid #ffc300; */
    border-radius: unset;
    background: var(--button-bg-color-2);
    color: var(--button-font-color-1);
    padding: 10px 0px;
    font-size: 16px;
    font-weight: 600;
}

.table-heading {
    font-size: 16px;
    font-family: 'Sora-bold' !important;
    color: #6c757d;
}

.currency-chooser {
    display: flex;
    align-items: center;
    justify-content: center;
}

.currency-chooser-label {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.currency-chooser label {
    cursor: pointer;
    color: #fff;
}

.currency-chooser .input-currency {
    box-shadow: 0px 0px 0px 1px #6d6d6d;
    font-size: 3em;
    width: 25px;
    height: 25px;
    margin-right: 7px;
    border: 4px solid #fff;
    background-clip: border-box;
    border-radius: 50%;
    appearance: none;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.currency-chooser label {
    display: flex;
    margin: 10px 15px;
}

.input-currency.sol:checked {
    box-shadow: 0px 0px 0px 4px #212529;
    background-color: #212529;
}

.input-currency.usdc:checked {
    box-shadow: 0px 0px 0px 4px #212529;
    background-color: #212529;
}

@media only screen and (max-width: 600px) {
    /* .cart-table-head tr th{
        font-size: 15px;
    } */
    .shopping-cart-main {
        margin-top: 12%;
        margin-bottom: 25%;
    }
    .shopping-card {
        padding: 0px;
        margin-right: 0px
    }
    .mobile-place-order,
    .mobile-cart-table {
        display: block;
    }
    .desktop-place-order,
    .desktop-cart-table {
        display: none;
    }
    .cart-heading h1 {
        font-size: 25px;
    }
    .shopping-card-body h1 {
        font-size: 22px;
    }
    .shopping-card-body h3 {
        font-size: 15px;
    }
    .cart-table-body tr td h3 {
        font-size: 15px;
    }
    .cart-table-body tr td h5 {
        font-size: 15px;
    }
    .cart-table-body tr td .quantity h1 {
        font-size: 18px;
    }
    .cart-table-body tr td .quantity img {
        width: 25px;
        height: 25px;
        margin-top: 4px;
    }
    .table-row-total {
        font-size: 13px;
        width: 60px;
        padding-top: 4px !important;
    }
    .cart-table-body tr td {
        padding-top: 20px;
        padding-bottom: 25px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .shadow-btn-width img {
        padding-left: 1%;
    }
    .table-product-detail {
        width: 156px;
    }
    .cart-table-head tr,
    .cart-table-body tr {
        border-bottom: 1px solid #edf2f7;
        border-top: 1px solid #edf2f7;
    }
    .card-heading {
        border-bottom: 1px solid #cbd5e0;
    }
}