.banner-img .banner-text h4 {
    background: #26283bbd;
    padding: 15px;
    border-radius: 10px;
    min-height: unset;
    color: #fff;
}

.section-title h3 {
    color: #fff;
}

.home-cat-shop-now-btn {
    font-size: 18px !important;
}

.spinner-container-v1 {
    /* width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  background-color: #ffffff; */
    position: relative;
    text-align: center;
    margin-bottom: 100px;
}

.spinner-v1 {
    margin: auto;
    border: 4px solid #dbf2ff;
    /*
change to dotted for something cool.
change width to 1px for tapered bar
*/
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    top: 45%;
    border-radius: 50%;
    border-right: 4px solid #018df7;
    text-align: center;
    animation-name: spin;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    /*animation-timing-function: linear; /*linear spin */
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    /*50% {width:24px;height:24px} /*shrinking effect*/
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    /*50% {width:24px;height:24px} /*shrinking effect*/
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.product-cart-wrap .product-card-bottom .add-cart .add,
.product-cart-wrap .product-card-bottom .add-cart .add:hover {
    background-color: #018df7;
    color: #fff;
}

.first-section {
    margin-left: 100px;
    margin-right: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.f-s-heading {
    color: #ffc300;
    /* Heading 1 */
    font-family: Titan One;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.f-s-sub-heading {
    color: #fff;
    font-family: Sora-regular;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    padding-top: 50px;
    width: 70%;
    /* 150% */
}

.f-s-features {
    border-radius: 12px;
    /* opacity: 0.3; */
    background: #130f01;
    padding: 10px 10px;
    width: fit-content;
    margin-top: 50px;
}

.f-s-features span {
    color: #fff;
    padding: 0px 20px;
    display: inline-flex;
}

.f-s-features span img {
    padding-right: 5px;
}

.f-s-features span:first-child,
.f-s-features span:nth-child(2) {
    border-right: 1px solid #ffc30080;
}

.f-s-t-shirt-img {
    /* background-image: url(../images/Rectangle\ 163.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
}

.f-s-t-shirt-img img {
    margin: auto;
    /* padding: 70px; */
}

.f-s-button {
    padding-top: 50px;
}

.second-section {
    margin-left: 150px;
    margin-right: 150px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.s-s-heading {
    color: #fff;
    text-align: center;
    /* Heading 3 */
    font-family: Titan One;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 30px;
    /* background-image: url(../images/Ellipse\ 313.png);
    background-repeat: no-repeat;
    background-size: 0%; */
}

.s-s-sub-heading {
    color: #fff;
    text-align: center;
    font-family: Sora-regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;
}

.third-section {
    padding-top: 100px;
    padding-bottom: 100px;
    background: #1b1503;
}

.fourth-section {
    padding-top: 100px;
    padding-bottom: 100px;
}

.h-w-card {
    border-radius: 15px;
    padding: 30px;
}


/* Top left corner border */

.h-w-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    /* Adjust the width of the L-shaped border */
    height: 90%;
    /* Adjust the height of the L-shaped border */
    border-radius: 15px 0px 15px 0px;
    border-top: 0.5px solid #ffc300;
    /* Set the color for the top border */
    border-left: 0.5px solid #ffc300;
}


/* Bottom right corner border */

.h-w-card::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90%;
    /* Adjust the width of the L-shaped border */
    height: 90%;
    /* Adjust the height of the L-shaped border */
    border-radius: 15px 0px 15px 0px;
    border-bottom: 0.5px solid #ffc300;
    /* Set the color for the bottom border */
    border-right: 0.5px solid #ffc300;
}

.h-w-card .card-body {
    display: flex;
}

.h-w-card .h-w-img {
    margin-right: 10px;
}

.h-w-card .h-w-text {
    color: #fff;
    /* font-family: Inter; */
    font-family: Sora-regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
}

.h-w-no {
    position: absolute;
    top: -10px;
    left: -5px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    font-family: Titan One;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #010100;
    background: radial-gradient(82.95% 82.95% at 27.86% 30.02%, #fffab7 0%, #ffc300 34.28%, #ce8e23 66.67%, #b48900 100%, #824a11 100%);
}

.h-w-s-row {
    margin-top: 30px !important;
    margin-left: 100px !important;
    margin-right: 100px !important;
}

.r-c-s-card {
    border-radius: 15px;
    background: linear-gradient(153deg, rgba(255, 195, 0, 0) -32.25%, rgba(255, 195, 0, 0.5) 124.59%), rgba(0, 0, 0, 0.5);
    text-align: center;
    width: auto;
    cursor: pointer;
}

.r-c-s-img img {
    margin: auto;
}

.r-c-s-name {
    color: #fff;
    font-family: Sora-regular;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 5px;
}

.r-c-s-category {
    color: #ffc300;
    font-family: Sora-regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.r-c-s-currency-logo {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.r-c-s-currency-logo img {
    width: 25px;
    margin-left: 5px;
}

.r-a-p-card {
    border-radius: 15px;
    border: 1px solid #ffc300;
    background: linear-gradient(333deg, rgba(255, 195, 0, 0.14) -1.62%, rgba(255, 195, 0, 0) 92.65%);
    cursor: pointer;
}

.r-a-p-name {
    color: #fff;
    text-align: center;
    /* Body 1 */
    font-family: Sora-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 10px;
}

.r-a-p-price {
    color: #FFC300;
    font-family: Sora-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.r-a-p-img img {
    margin: auto;
}

.fifth-section {
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.c-f-card {
    border-radius: 15px !important;
    border: 1px solid #ffc300;
    background: linear-gradient(333deg, rgba(255, 195, 0, 0) -1.62%, rgba(255, 195, 0, 0.2) 92.65%);
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.c-f-card .card-body {
    display: flex;
    padding: 20px;
}

.c-f-heading {
    color: #ffc300;
    font-family: Sora-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 40px;
    margin-bottom: 10px;
}

.c-f-content {
    color: #fff;
    font-family: Sora-regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 70%;
    margin-left: 40px;
    /* 156.25% */
}

.o-f-card .card-body {
    display: flex;
}

.o-f-heading {
    color: #ffc300;
    font-family: Sora-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
    margin-bottom: 5px;
}

.o-f-content {
    color: #fff;
    font-family: Sora-regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 20px;
    width: 70%;
    /* 157.143% */
}

.faq-accordian .accordion-item,
.faq-accordian .accordion-header,
.faq-accordian .accordion-button,
.faq-accordian .accordion-button:not(.collapsed) {
    background: unset;
    border: unset;
}

.faq-accordian .accordion-item:not(.collapsed) {
    border: 1px solid rgba(255, 195, 0, 0.2);
    border-radius: 15px !important;
}

.faq-accordian .accordion-item {
    margin-bottom: 10px;
}

.accordion-button {
    color: #fff !important;
    font-family: Sora-regular;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.accordion-button:not(.collapsed) {
    color: #ffc300 !important;
    font-family: Sora-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
}

.accordion-body {
    color: #fff;
    font-family: Sora-regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    opacity: 0.7;
    /* 187.5% */
}

.accordion-button:not(.collapsed) {
    box-shadow: unset !important;
}

.accordion-button::after {
    background-image: url(../images/down-arrow.png) !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../images/Vector.png) !important;
}

.six-section {
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
    padding-bottom: 100px;
    background: #1b1503;
}

.seven-section {
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.eight-section {
    margin-left: 200px;
    margin-right: 200px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.c-a-container {
    border-radius: 15px;
    border: 0.5px solid #ffc300;
    padding: 58px;
    background: #281f02;
}

.c-a-heading {
    color: #ffc300;
    /* Heading 2 */
    font-family: Titan One;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.c-a-sub-heading {
    color: #ffc300;
    font-family: Sora-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}

.c-a-top-image {
    position: absolute;
    top: 50px;
    left: 20px;
}

.c-a-bottom-image {
    position: absolute;
    right: 0px;
}


/* CustomSlider.css */

.product-slider-container,
.store-slider-container {
    width: 90%;
    /* Adjust the width as needed */
    margin: 0 auto;
    /* Center the slider horizontally */
}

.slider-arrows {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    margin: 0 auto;
}

.slider-arrow-left,
.slider-arrow-right {
    background-color: #261d05;
    color: #fff;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #FFC30033;
    margin-left: 10px;
}

.slider-arrow-left svg,
.slider-arrow-right svg {
    margin: auto;
}

.view-all-btn {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    margin: 0 auto;
    cursor: pointer;
}

.view-all-btn p {
    color: #FFC300;
    font-family: Sora-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.view-all-btn svg {
    font-size: 20px;
    color: #FFC300;
    padding-left: 5px;
}

@media only screen and (max-width: 600px) {
    .second-section {
        margin-left: unset;
        margin-right: unset;
    }

    .h-w-s-row {
        margin-left: unset !important;
        margin-right: unset !important;
    }

    .first-section {
        margin-left: unset;
        margin-right: unset;
    }

    .eight-section {
        margin-left: unset;
        margin-right: unset;
    }

    .fifth-section {
        padding-left: unset;
        padding-right: unset;
    }

    .six-section {
        padding-left: unset;
        padding-right: unset;
    }

    .seven-section {
        padding-left: unset;
        padding-right: unset;
    }
}

/** How it's works design */
.process {
    width: 100%;
    padding: 0 15px;
    text-align: center;
}

.process__item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    position: relative;
    padding: 15px 35px;
    transition: 0.4s ease-in-out;
}

.process__item:hover {
    background: #151102;
}

.process__item:hover .process__number {
    transform: translateY(5px);
    color: #fff;
}

.process__number {
    font-size: 70px;
    display: block;
    color: #ffc300;
    font-family: Titan One;
    font-weight: 700;
    transition: 0.4s ease-in-out;
}

.process__title {
    display: block;
    font-family: Sora-regular;
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 25px;
    color: #ffc300;
    text-transform: uppercase;
    margin-top: 30px;
}

.process__subtitle {
    display: block;
    font-family: Sora-regular;
    font-size: 18px;
    color: #fff;
    margin-top: 20px;
    line-height: 1.5;
}

@media (min-width: 768px) {
    .process {
        display: inline-block;
    }

    .process__item {
        width: 49%;
        display: inline-block;
    }

}

@media (min-width: 1200px) {
    .process {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .process__item {
        width: 100%;
    }

    .process__item:not(:last-of-type)::after {
        content: "";
        width: 1px;
        height: 75%;
        background: #FFC30033;
        position: absolute;
        right: 0;
        top: 50%;
        opacity: 0.2;
        transform: translateY(-50%);
    }
}

/* RoadMap */
.fourth-section {
    background:

        linear-gradient(90deg, #1b1503 50%, #151102 0);
}

.timeline {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 220px);
    justify-content: center;
}

.timeline li {
    grid-column: span 2;
    text-align: center;
    border-radius: 65px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5em;
    font-family: sans-serif;
    position: relative;
    padding: 10px;
    border: 12px solid #0000;
    background: linear-gradient(333deg, rgba(255, 195, 0, 0) -1.62%, rgba(255, 195, 0, 0.2) 92.65%) content-box,
        linear-gradient(var(--c, #281f02) 0 0) padding-box,
        linear-gradient(var(--d, 90deg), #0000 75%, #ffc300 0) border-box;
}

.timeline li:nth-child(even) {
    grid-column-end: -1;
    --d: -90deg;
    --c: #281f02;
}

.timeline li:not(:last-child) {
    margin-bottom: -12px;
}

.timeline li:before,
.timeline li:after {
    content: "";
    display: block;
    height: 0.6em;
}

time {
    position: absolute;
    font-size: 1.2em;
    left: calc(100% + 50px);
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    color: #ffc300;
    font-family: Sora-regular;
}

.timeline li:nth-child(even) time {
    left: auto;
    color: #ffc300;
    right: calc(100% + 50px);
    font-family: Sora-regular;
}

time:before {
    content: "";
    position: absolute;
    height: 10px;
    top: calc(50% - 5px);
    right: calc(100% + 5px);
    width: 65px;
    background: repeating-linear-gradient(-90deg, #fcd000 0 5px, #0000 0 10px) 50%/100% 2px no-repeat;
}

.timeline li:nth-child(even) time:before {
    right: auto;
    left: calc(100% + 5px);
    background: repeating-linear-gradient(90deg, #ffc300 0 5px, #0000 0 10px) 50%/100% 2px no-repeat;
}

.timeline h1,
.timeline p {
    display: none;
}

.timeline-text {
    font-size: 15px;
    font-family: Sora-regular;
}

/** Timeline Mobile */
/* Timeline Container */
.roadmap-mobile {
    display: none;
}

.timeline-mobile {
    background: var(--primary-color);
    margin: 20px auto;
    padding: 20px;
}

/* Card container */
.timeline-mobile-card {
    position: relative;
    max-width: 400px;
}

/* setting padding based on even or odd */
.timeline-mobile-card:nth-child(odd) {
    padding: 30px 0 30px 30px;
}

.timeline-mobile-card:nth-child(even) {
    padding: 30px 30px 30px 0;
}

/* Global ::before */
.timeline-mobile-card::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid #fcd000;
}

/* Setting the border of top, bottom, left */
.timeline-mobile-card:nth-child(odd)::before {
    left: 0px;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
}

/* Setting the border of top, bottom, right */
.timeline-mobile-card:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.timeline-mobile-card:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.timeline-mobile-card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.timeline-mobile-card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
}

/* Information about the timeline */
.timeline-mobile-card .info {
    display: flex;
    flex-direction: column;
    background: linear-gradient(333deg, rgba(255, 195, 0, 0) -1.62%, rgba(255, 195, 0, 0.2) 92.65%);
    color: fff;
    border-radius: 10px;
    padding: 10px;
}
.timeline-mobile-card .info p{
    color: #fff;
}

/* Title of the card */
.timeline-mobile-card .title {
    color: #ffc300;
    position: relative;
}

/* Timeline dot  */
.timeline-mobile-card .title::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 999px;
    border: 3px solid #fcd000;
}

/* text right if the card is even  */
.timeline-mobile-card:nth-child(even)>.info>.title {
    text-align: right;
}

/* setting dot to the left if the card is odd */
.timeline-mobile-card:nth-child(odd)>.info>.title::before {
    left: -45px;
}

/* setting dot to the right if the card is odd */
.timeline-mobile-card:nth-child(even)>.info>.title::before {
    right: -45px;
}


/* Show the div on screens that are 768px wide or less */
@media (max-width: 768px) {

    .roadmap-web {
        display: none;
    }

    .roadmap-mobile {
        display: block;
    }
}