.mycart-checkout{
    float: right;
}
.margin0{
    margin-top: 0px;
}
.my-cart-main{
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 3%;
    margin-bottom: 3%;
}