footer {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.45) -4%, rgba(227, 174, 0, 0.35) 50.42%, rgba(1, 1, 0, 0.33) 101.85%);
    /* background: linear-gradient(270deg, #141414 -4.42%, #201a07 105.38%); */
    /* position: fixed; */
    width: 100%;
    bottom: 0px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 30px;
}

.footer-container {
    margin-left: 100px;
    margin-right: 100px;
}

.footer-first-column {
    border-right: 1px solid #FFC30033;
}

.mobile-social-icon a img {
    max-width: 20px;
    /* width: 16px; */
    /* height: 18px; */
    margin: auto;
}

.mobile-social-icon a {
    background-color: unset;
    border-radius: unset;
}

.footer-logo {
    width: 90px;
}

.footer-social-icons {
    display: flex;
}

.footer-social-icons img {
    border: 1px solid #ffc4005e;
    border-radius: 30px;
    margin-right: 10px;
    padding: 5px;
}

.footer-text {
    color: #fff;
    opacity: 0.7;
}

.footer-menu-1-heading {
    font-weight: 600 !important;
    color: #fff !important;
}

.footer-menu-1 li {
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    font-family: Sora-regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 43.5px;
}

.copy-right-symbol {
    text-align: center;
    color: rgba(255, 255, 255, 0.80);
    /* Lable 1 */
    font-family: Sora-regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 600px) {
    footer {
        padding-left: unset;
        padding-right: unset;
    }
}