.breadcrumbs-list li {
    display: inline;
    color: var(--font-color-2);
    font-size: 18px;
    font-family: 'Sora-regular' !important;
    padding-left: 5px;
    padding-right: 5px;
}

.breadcrumbs-product-name {
    color: var(--font-color-1) !important;
}

.breadcrumbs-list li a {
    color: var(--font-color-2);
    text-decoration: none;
    font-size: 15px;
    text-transform: capitalize;
}

.btn-tranparent,
.btn-tranparent:hover {
    background: transparent;
    border: 2px solid #ffc300;
    border-radius: 5px;
    margin-top: 10px;
}

.product-content div {
    margin-top: 20px;
    margin-bottom: 20px;
}

.product-label {
    color: var(--font-color-3);
    font-family: 'Sora-regular' !important;
    font-size: 18px;
    margin-right: 20px;
}

.product-label-bold {
    color: var(--font-color-3);
    font-family: 'Sora-bold' !important;
    font-size: 18px;
}

.productCost h1 {
    font-size: 25px;
    color: var(--font-color-2);
    font-family: 'Sora-bold' !important;
}

.product-condition,
.product-desc {
    margin-top: 50px !important;
}

.product-desc p,
.product-condition p {
    margin-top: 10px;
    color: var(--font-color-2);
    font-family: 'Sora-regular' !important;
    font-size: 18px;
}

.product-editions span {
    color: #FFC300;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #FFC300;
    font-size: 18px;
    font-family: 'Sora-regular' !important;
    display: inline-flex;
}

.product-name h2 {
    font-size: 45px;
    font-family: 'Sora-bold' !important;
    color: var(--font-color-2);
    text-transform: capitalize;
}

.product-editions span img {
    padding-right: 10px;
}

.product-details {
    margin-top: 40px;
}

.prodcut-images,
.product-color div,
.product-size div {
    display: inline-flex;
}


/* .prodcut-images div{
    margin: 25px;
} */

.product-img {
    background: #d4cdcd;
    border-radius: 10px;
    margin: 10px;
}

.product-quantity div,
.productCost {
    display: inline-flex;
}

.productCost h1 {
    padding-left: 10px;
}

.product-quantity h1 {
    color: var(--font-color-2);
    font-family: 'Sora-regular' !important;
    font-size: 25px;
    padding: 2px 20px;
}

.productCost img {
    width: 30px;
    height: 30px;
}

.product-quantity img {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.product-color div img,
.product-size div img {
    padding-right: 10px;
}

.product-general-detail {
    margin-top: 30px;
    background: var(--body-color-3);
    padding: 5% 20% 5% 20%;
}

.product-general-detail-row {
    padding-top: 5%;
}

.product-general-detail-col {
    display: inline-flex;
    margin-top: 30px;
}

.product-general-detail-col div img {
    padding-right: 20px;
    width: 85px;
}

.product-general-detail-col div h2 {
    color: #ffffff;
    font-size: 25px;
    font-family: 'Sora-bold' !important;
}

.product-general-detail-col div p {
    margin-top: 20px;
    color: #ffffff;
    font-size: 20px;
    font-family: 'Sora-regular' !important;
}


/* .shawdow-btn{
    border: 2px solid #ffc300;
    background: #000000;
    color: #ffc300;
    font-size: 18px;
    box-shadow: -4px 10px 6px #ffc300, -4px 3px 6px #ffc300;
}*/

.shawdow-btn:active,
.shadow-btn:focus {
    border: 2px solid #ffc300 !important;
    background-color: #000000 !important;
    color: #ffc300 !important;
}

.shadow-btn-width {
    width: 300px;
}

.shadow-btn-width img {
    padding-left: 20%;
    display: inline-flex;
}

.sizeBtn {
    background: transparent;
    border: 1px solid var(--border-color-2) !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    margin-right: 10px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    color: var(--font-color-3) !important;
}

.sizeBtnActive {
    background: transparent;
    border: 4px solid var(--font-color-1) !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    margin-right: 10px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    color: var(--font-color-3) !important;
    box-shadow: 0px 3px 7px #ffc300;
}

.colorBtn {
    border: 1px solid #ffffffac !important;
    border-radius: 10px !important;
    margin-right: 10px;
    padding-top: 20px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-bottom: 20px !important;
}

.colorBtnActive {
    border: 4px solid #FFC300 !important;
    border-radius: 10px !important;
    margin-right: 10px;
    padding-top: 20px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-bottom: 20px !important;
    box-shadow: 0px 3px 7px #ffc300;
}

.colorRed {
    background-color: red !important;
}

.colorWhite {
    background-color: white !important;
}

.colorBlack {
    background-color: black !important;
}

.colorBlue {
    background-color: blue !important;
}

.colorGreen {
    background-color: green !important;
}

.desktop-cart-button {
    display: block;
}

.mobile-cart-button {
    display: none;
}

.mobile-cart-button button {
    width: 100% !important;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    border: none;
    /* border-top: 2px solid #ffc300; */
    padding: 10px 0px;
    border-radius: unset;
    /* font-weight: 600; */
}

.thumbnail-images img {
    width: 100px;
    padding: 1px;
    background: #d4cdcd;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
}

.large-image img {
    margin: auto;
    width: inherit;
}

.prodcut-images {
    width: 100%;
    display: flex;
}

.thumbnail-images {
    width: 20%;
}

.large-image {
    width: 80%;
    /* background: #d4cdcd; */
    border-radius: 10px;
}

.thumbnail-images .selected {
    border: 2px solid var(border-color-1) !important;
}

.mobile-product-icon {
    display: none;
}

.desktop-product-icon {
    display: block;
}

.btn-icons {
    display: inline;
    color: var(--icon-color-1);
    font-weight: 600;
}

.page-icons {
    color: var(--icon-color-2);
    font-weight: 600;
}

.page-icons-2 {
    color: var(--icon-color-1);
    font-weight: 600;
    font-size: 30px;
}

@media only screen and (max-width: 600px) {
    .product-img-select {
        display: none;
    }
    .product-img {
        margin: 0;
    }
    .breadcrumbs-list li {
        font-size: 15px;
    }
    .product-editions span img {
        width: 17%;
    }
    .product-editions span {
        font-size: 12px;
    }
    .product-editions {
        text-align: center;
    }
    .product-name h2 {
        font-size: 26px;
    }
    .product-label {
        font-size: 15px;
        margin-right: 5px;
    }
    .form-check-inline {
        margin-right: 5px !important;
    }
    .payment-radio,
    .product-payment {
        margin-top: 0px !important;
    }
    .product-general-detail {
        padding: 5% 8% 5% 8%;
    }
    .product-general-detail-col div img {
        width: 150px;
    }
    .desktop-cart-button {
        display: none;
    }
    .mobile-cart-button {
        display: block;
    }
    .product-general-detail-col div h2 {
        font-size: 20px;
    }
    .product-general-detail-col div p {
        font-size: 16px;
    }
    .product-content div {
        margin-top: 0px;
        margin-bottom: 10px
    }
    .mobile-col-width-6 {
        width: 50% !important;
    }
    .product-general-detail-col div img {
        width: 50px;
        margin-bottom: 10px;
    }
    .product-general-detail {
        margin-top: 30px;
        padding: 5% 8% 18% 8%;
    }
    .product-general-detail-col div h2 {
        font-size: 20px;
    }
    .product-general-detail-col div p {
        font-size: 17px;
    }
    .mobile-product-icon {
        display: block;
    }
    .desktop-product-icon {
        display: none;
    }
    .product-color,
    .product-size,
    .product-quantity,
    .product-desc,
    .product-condition {
        border-top: 1px solid #edf2f7;
        padding-top: 13px;
    }
    .breadcrumbs {
        display: none;
    }
    .product-details {
        margin-top: unset;
    }
    .productCost {
        padding-top: 10px;
    }
}

.swal2-popup {
    background: var(--popup-bg-color-1) !important;
}

.swal2-title {
    color: var(--popup-font-color-1) !important;
}

.swal2-html-container {
    color: var(--popup-font-color-1) !important;
}

.swal2-confirm {
    background-color: var(--popup-button-bg-color-2) !important;
    color: var(--popup-button-font-color-2) !important;
}