.page-heading {
    color: #ffc300;
    font-family: Sora-regular;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 50px;
}

.back-to-home {
    color: #D9D9D9;
    font-family: Sora-regular;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    padding-bottom: 50px;
    cursor: pointer;
}

.store-list {
    padding-bottom: 100px;
}