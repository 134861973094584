.home-tab {
  font-weight: 600;
  font-size: 15px;
  color: #C6C8DA;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}


.home-tab.active {
  background: #FFC300;
  color: #000;
}


.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.grid>article {
  /* border: 1px solid #ccc; */
}

.grid>article img {
  max-width: 100%;
  background: #D9D9D9;
  width: 321px;
  height: 397px;
  border-radius: 10px;
}

.grid .text {
  padding: 20px;
  text-align: center;
}

.seller-product-container .product-name {
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #C6C8DA;
}


.page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height */

  color: #FFC300;
}

.back-to {
  cursor: pointer;
}

.back-to .text {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #D9D9D9;
}



.back-to .icon {
  color: #D9D9D9;
  font-size: 22px;
  vertical-align: middle;
  padding-right: 10px;
}


.color-picker {
  border: 0.745564px solid #FFFFFF;
  margin: 0.1rem !important;
  width: 25px;
  height: 25px;
}

.color-box-container {
  align-items: center;
  justify-content: center;
}

.price-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */


  color: #C6C8DA;
}

.shipping-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */


  color: #C6C8DA;
}

.size-text{
  font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
/* identical to box height */
text-align: center;

color: #C6C8DA;

}
