.check-out-main {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.checkout-heading h1 {
    color: var(--font-color-1);
    font-family: "Sora-bold" !important;
    font-size: 35px;
}

.disclaimer p {
    color: var(--font-color-4);
    font-family: "Sora-Regular" !important;
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid var(--border-color-2);
    padding: 15px 25px;
}

.checkout-subhead h2 {
    color: var(--font-color-2);
    font-family: "Sora-bold" !important;
    font-size: 25px;
}

.checkout-subhead {
    margin-bottom: 5%;
}

.checkout-subhead-contact {
    margin-top: 30px;
}

.checkout-subhead span {
    float: right;
    font-family: "Sora-regular" !important;
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
}

.address-del span {
    float: right;
    font-family: "Sora-regular" !important;
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
}

.address-div {
    border: 1px solid #3a2e21;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.active-address {
    border: 2px solid var(--font-color-1);
}

.address-head {
    display: block;
    color: #ffffff;
}


/* .address-head{
    float: left;
} */

.address-del {
    font-size: 20px;
    font-family: "Sora-regular" !important;
    color: var(--font-color-3);
}

.address-head-action {
    display: inline-flex;
    float: right;
}

.address-head-action h3 {
    padding-right: 10px;
}

.address-edit {
    font-size: 14px;
    text-decoration: underline;
    color: var(--font-color-1);
    font-family: "Sora-regular" !important;
    cursor: pointer;
}

.address-delete {
    font-size: 14px;
    text-decoration: underline;
    color: #ff073a;
    font-family: "Sora-regular" !important;
    cursor: pointer;
}

.address-details {
    display: block;
    color: var(--font-color-2);
}

.address-details h3 {
    font-size: 18px;
    font-family: "Sora-regular" !important;
}

.checkout-content {
    margin-top: 5%;
}

.shadow-btn {
    border: 2px solid #ffc300;
    background: #000000;
    color: #ffc300;
    font-size: 16px;
    box-shadow: -4px 10px 6px #ffc300, -4px 3px 6px #ffc300;
}

.shadow-btn:active,
.shadow-btn:focus,
.shadow-btn:hover {
    /* border: 2px solid #ffc300 !important; */
    background-color: var(--button-bg-color-2) !important;
    color: var(--button-font-color-1) !important;
}

.shadow-btn-width {
    width: 300px !important;
}

.shadow-btn-width img {
    padding-left: 10%;
    display: inline-flex;
}

.checkout-card {
    margin-top: 30px;
    background: linear-gradient(180deg, var(--body-color-4), var(--body-color-4));
    border: none;
    border-radius: 15px;
    padding: 15px;
    margin-right: 50px;
}

.checkout-card-body p {
    color: var(--font-color-1);
    font-family: "Sora-regular" !important;
    font-size: 14px;
}

.checkout-card-body h3 {
    color: var(--font-color-2);
    font-family: "Sora-regular" !important;
    font-size: 20px;
}

.checkout-card-body h6 {
    color: var(--font-color-3);
    font-family: "Sora-regular" !important;
    font-size: 14px;
}

.checkout-card-body h6 span {
    color: #ffffff;
    font-family: "Sora-bold" !important;
    font-size: 16px;
    float: right;
}

.checkout-each {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border-color-2);
}

.product-subtotal {
    margin-top: 30px;
}

.product-subtotal h5 {
    color: var(--font-color-2);
    font-size: 16px;
    font-family: "Sora-regular" !important;
    margin-top: 20px;
}

.product-subtotal h5 span {
    float: right;
}

.product-total {
    margin-top: 30px;
}

.product-total h3 {
    color: var(--font-color-1);
    font-size: 20px;
    font-family: "Sora-bold" !important;
}

.product-total h3 span {
    float: right;
}

.check-out-form .form-input,
.check-out-form .form-input:focus,
.check-out-form .form-input:active {
    color: #000000;
    background: #d9d9d9;
    margin-top: 30px;
    font-family: "Sora-regular" !important;
}

.check-out-form .form-input::placeholder {
    font-family: "Sora-regular" !important;
}

.form-check {
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--font-color-3);
}

.form-check input:checked {
    background-color: var(--font-color-1) !important;
    border-color: var(--font-color-1) !important;
}

.col-width-3 {
    width: 75%;
    float: left;
}

.col-width-9 {
    width: 25%;
    float: left;
}

.mobile-address,
.mobile-checkout-product {
    display: none;
}

.desktop-address,
.desktop-checkout-product {
    display: block;
}

.mobile-pay-button {
    width: 100% !important;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    border: none;
    /* border-top: 2px solid #ffc300; */
    border-radius: unset;
    background: var(--button-bg-color-2);
    color: var(--button-font-color-1);
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
}

.add-address-btn {
    background-color: var(--button-bg-color-3);
    color: var(--button-font-color-1);
    padding: 10px 10px;
    font-size: 16px;
    border: unset;
}

.close-address-btn {
    margin-left: 10px;
    background-color: var(--button-bg-color-3);
    color: var(--button-font-color-1);
    padding: 10px 10px;
    font-size: 16px;
    border: unset;
}

@media only screen and (max-width: 600px) {
    .checkout-heading h1 {
        font-size: 25px;
    }
    .disclaimer p {
        font-size: 15px;
    }
    .checkout-subhead h2 {
        font-size: 20px;
    }
    .checkout-card {
        padding: 5px;
        margin-right: 0px;
    }
    .col-width-3 {
        width: 50%;
    }
    .col-width-9 {
        width: 50%;
    }
    .mobile-address {
        display: block;
        background: linear-gradient( 180deg, var(--body-color-4), var(--body-color-4));
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 10px;
    }
    .desktop-address {
        display: none;
    }
    .address-del {
        font-size: 16px;
    }
    .checkout-subhead span,
    .address-del span {
        font-size: 15px;
        margin-top: 2px;
    }
    .address-details h3 {
        font-size: 15px;
    }
    .mobile-pay-btn {
        position: fixed;
        width: 100%;
        left: 0;
        z-index: 5;
        bottom: -5px;
        margin-bottom: 124px;
    }
    .knSIuV {
        display: none;
    }
    .mobile-pay-btn button {
        border-radius: unset;
    }
    .mobile-checkout-product {
        display: block;
    }
    .desktop-checkout-product {
        display: none;
    }
    .check-out-main {
        padding-top: 12%;
        padding-bottom: 20%;
    }
    .check-out-form .form-input::placeholder {
        font-family: "Sora-regular" !important;
        font-size: 15px;
    }
    .check-out-form select {
        font-size: 15px;
    }
    .checkout-card-body h3 {
        font-size: 15px;
    }
}

.address-container {
    max-width: 99vw;
    margin: 15px auto;
    padding: 0 15px;
}

.top-text-wrapper {
    margin: 20px 0 30px 0;
}

.top-text-wrapper h4 {
    font-size: 24px;
    margin-bottom: 10px;
}

.top-text-wrapper code {
    font-size: 0.85em;
    background: linear-gradient(90deg, #fce3ec, #ffe8cc);
    color: #ff2200;
    padding: 0.1rem 0.3rem 0.2rem;
    border-radius: 0.2rem;
}

.tab-section-wrapper {
    padding: 30px 0;
}


/* ******************* Main Styeles : Radio Card */

.address-container label.radio-card {
    cursor: pointer;
}

.address-container label.radio-card .card-content-wrapper {
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
    transition: 200ms linear;
}

.address-container label.radio-card .check-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: solid 2px #e3e3e3;
    border-radius: 50%;
    transition: 200ms linear;
    position: relative;
}

.address-container label.radio-card .check-icon:before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear;
    opacity: 0;
}

.address-container label.radio-card input[type=radio] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: none;
}

.address-container label.radio-card input[type=radio]:checked+.card-content-wrapper {
    box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #3057d5;
}

.address-container label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
    background: #3057d5;
    border-color: #3057d5;
    transform: scale(1.2);
}

.address-container label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
    transform: scale(1);
    opacity: 1;
}

.address-container label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
    box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
    border-color: #3056d5;
}

.address-container label.radio-card .card-content img {
    margin-bottom: 10px;
}

.address-container label.radio-card .card-content h4 {
    font-size: 16px;
    /* color: #1f2949; */
    margin-bottom: 10px;
}

.address-container label.radio-card .card-content h5 {
    font-size: 14px;
    line-height: 1.4;
    color: #686d73;
}